<template>
  <div class="w-100 sliders-referees">
    <div class="container-fluid pt-lg-5 mt-lg-5">
      <div class="row pt-5">
        <div class="col-12">
          <h2
            class="text-uppercase tx__w-bold mx-auto text-center"
            v-if="title"
            v-html="title"
          ></h2>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-5 main-slider position-relative">
      <div class="row">
        <div class="col-12">
          <splide ref="primary" :options="optionsOne" :slides="datarefe">
            <splide-slide v-for="(i, index) in datarefe" :key="index">
              <div class="w-100 item-slider px-md-5 px-lg-0">
                <div class="w-100 px-3">
                <figure class="figure figure__portrait">
                  <img
                    v-if="i.fimg_url.full"
                    :src="i.fimg_url.full"
                    :alt="i.title.rendered"
                    class="img"
                  />
                  <img
                    v-else
                    :src="dummyimg"
                    :alt="i.title.rendered"
                    class="img"
                  />
                  <figcaption
                    class="d-none d-lg-block tx__line-height-sm"
                    v-html="i.content.rendered"
                  ></figcaption>
                  <figcaption class="d-lg-none">
                    <h3 class="text-center tx__size-r">{{ i.title.rendered }}</h3>
                    <span class="line"></span>
                    <div
                    class="text-center pt-2 p-m0 "
                    v-html="i.acf.position"
                  ></div>
                  </figcaption>
                </figure>
                </div>

                <div class="w-100 d-none d-lg-block name-referee text-white py-2">
                  <h3 class="text-center tx__size-r">{{ i.title.rendered }}</h3>
                  <span class="line"></span>
                  <div
                    class="text-center pt-2 p-m0"
                    v-html="i.acf.position"
                  ></div>
                  
                </div>
                <div class="w-100 d-lg-none text-white tx__size-sm"  v-html="i.content.rendered"></div>
              </div>
            </splide-slide>
          </splide>
        </div>
      </div>
    </div>
    
    <div class="container-fluid bg__secondary pb-5">
      <div class="row pt-lg-3 justify-content-center align-items-center">
       
        <div class="col-12 col-lg-10 d-none d-lg-block slider-ref-navi-one py-3 py-lg-0">
          <splide ref="secondary" :slides="datarefe" :options="optionsTwo">
            <splide-slide v-for="(i, index) in datarefe" :key="index">
              <div class="w-100 d-flex p-2 align-items-center item-slider">

                <figure class="figure figure__square m-0">
                  <img
                    v-if="i.fimg_url.square"
                    :src="i.fimg_url.square"
                    :alt="i.title.rendered"
                    class="img img--grayscale"
                  />
                  <img
                    v-else
                    :src="dummyimg"
                    :alt="i.title.rendered"
                    class="img"
                  />
                  <span class="stroke stroke-fill stroke-bottom-right "></span>
                </figure>
                
              </div>
            </splide-slide>
          </splide>
        </div>
        
        
      </div>
      
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide-core.min.css";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
export default {
  props: {
    datarefe: Array,
    title: String,
    dummyimg: String,
  },
  data() {
    return {
      reverseData: Array,
      optionsOne: {
        type: "loop",
        perPage: 3,
        perMove: 1,
        gap: "2rem",
        arrows: true,
        pagination: false,
        focus: "center",
        breakpoints: {
		      576: {
			      perPage: 1,
          },
          768: {
            perPage: 1,
            
          },
        }
      },
      optionsTwo: {
        type: "loop",
        gap: "1.5rem",
        arrows: false,
        pagination: false,
        perPage: 9,
        updateOnMove: true,
        isNavigation: true,
        focus: "center",
        drag: false,
        
        breakpoints: {
		      576: {
            perPage: 3,
          },
          768: {
			      perPage: 6,
          },
        }
      },
      isMounted: false,
    };
  },
  components: {
    Splide,
    SplideSlide,
  },
  methods: {
    goNext() {
      this.$refs.primary.go("+");
      this.$refs.tertiary.go("-");
    },
    goPrev() {
      this.$refs.primary.go("-");
      this.$refs.tertiary.go("+");
    },
    goToSlide(slide){
      this.$refs.primary.go(slide);
      this.$refs.tertiary.go(slide);
    }
  },
  created() {},
  mounted() {
    this.isMounted = true;
    if (this.$refs.primary && this.$refs.secondary) {
      console.log("Success");
      this.$refs.primary.sync(this.$refs.secondary.splide);
    } else {
      console.error();
    }
  },
};
</script>

<style>
</style>