<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-12 py-4">
        <h2
          v-if="title"
          class="text-uppercase tx__w-bold mx-auto text-center"
          v-html="title"
        ></h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 py-5 client-logos">
        <splide ref="clients" :options="options">
          <splide-slide v-for="(i, index) in data.acf.group_clients" :key="index">
            <div class="w-100 px-4 px-md-3 px-lg-2">
              
              <img
                :src="i.logo"
                alt="Client"
                class="img-fluid d-block px-xl-2 mx-auto"
              />
            </div>
          </splide-slide>
        </splide>
        
      </div>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import '@splidejs/splide/dist/css/splide-core.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
export default {
  props: {
    data: Object,
    title: String,
  },
  data(){
    return{
      options:{
        perPage: 5,
        arrows: true,
        pagination: false,
        type: "loop",
        gap: "2rem",
        breakpoints: {
		      576: {
            perPage: 2,
             gap: "0rem",
          },
          768: {
            perPage: 3,
            
          },
        }
      }
    }
  },
  components: {
    Splide,
    SplideSlide,
  },
  methods: {
    goNext(){
      this.$refs.clients.go( '+' );
    },
    goPrev(){
      this.$refs.clients.go( '-' );
    }
  },
};
</script>

<style>
</style>